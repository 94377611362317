import utils from '@/utils'

const QtApp = window.QtApp

const funcs = {
  settingsSet(/* name, value */) {},
  settingsGet(/* name */) {},
  videoLoadChannel(/* url */) {},
  videoPlay() {
    utils.dispatchEvent('videostart')
  },
  videoPause() {},
  videoStop() {},
  deviceInfo() {
    return QtApp.deviceInfo()
  },
  getVolume() { return 50 },
  setVolume(/* volume */) {},
  runYouTubeApp(/* model */) {},
  runWinkApp() {},
  showSystemSettings(/* page */) {},
  exitApp() { location.reload() },
}

export default (args) => {
  args = args || {}
  args.params = args.params || []
  return new Promise((resolve, reject) => {
    if (!funcs[args.fName]) {
      return reject(`Unknown function "${args.fName}" for QT device`)
    }
    resolve(funcs[args.fName](...args.params))
  })
}
